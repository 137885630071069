.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.Gradient1 {
  background-color: "linear-gradient(to top, #9e142a, black";
  font-family: "calibri";
}
.Calibri {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.Roboto {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.Browallia {
  font-family: "browallia";
}
.Cartogothic {
  font-family: "Tahoma";
}
.Anton {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.Gradient2 {
  background-color: "linear-gradient(to left, #9e142a, black";
  font-family: "calibri";
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.marquee {
  overflow: hidden;
  background: #ac2d5a;
}
.marqueeText {
  display: inline-block;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 40%;
  animation: marquee 25s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translate(-100%);
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.shrink-text {
  font-size: 1rem;
}
@media (max-width: 576) {
  .shrink-text {
    font-size: 0.8rem;
  }
}
@media (max-width: 768) {
  .shrink-text {
    font-size: 0.9rem;
  }
}
@media (max-width: 576px) {
  .text-break {
    word-break: break-word;
  }
}
@media (max-width: 768px) {
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media (max-width: 576px) {
  .text-sm {
    font-size: 0.8rem;
  }
}
@media (max-width: 768px) {
  .text-xs {
    font-size: 0.7rem;
  }
}
.testimonialCarousel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.testimonialCarousel__container {
  position: relative;
  width: 100%;
  height: 100%;
}

.testimonialCarousel__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.testimonialCarousel__item--active {
  opacity: 1;
  z-index: 1;
}

.testimonialCarousel__item--previous,
.testimonialCarousel__item--next {
  opacity: 0.5;
  z-index: 0;
  transition: opacity 0.5s ease;
}

.testimonialCarousel__item--previous {
  transform: translateX(-100%);
}

.testimonialCarousel__item--next {
  transform: translateX(100%);
}

.testimonialCarousel__item--center {
  transform: scale(1.2);
  z-index: 2;
}

.smallScreen {
  opacity: -1;
}
@media screen and (max-width: 768px) {
  .smallScreen {
    margin-top: 50px;
    padding-top: 9px;
  }
}
/* testomial section */
.shadow-effect {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #ececec;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.02);
}
#customers-testimonials .shadow-effect p {
  font-family: inherit;
  font-size: 17px;
  line-height: 1.5;
  margin: 0 0 17px 0;
  font-weight: 300;
}
.testsimonial-name {
  margin: -17px auto 0;
  display: table;
  width: auto;
  background: #3190e7;
  padding: 9px 35px;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  box-shadow: 0 9px 18px rgba(0, 0, 0, 0.12), 0 5px 7px rgba(0, 0, 0, 0.05);
}
#customers-testimonials .item {
  text-align: center;
  padding: 50px;
  margin-bottom: 80px;
  opacity: 0.2;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#customers-testimonials .owl-item.active.center .item {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}
.owl-carousel .owl-item img {
  transform-style: preserve-3d;
  max-width: 90px;
  margin: 0 auto 17px;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot.active span,
#customers-testimonials.owl-carousel .owl-dots .owl-dot:hover span {
  background: #3190e7;
  transform: translate3d(0px, -50%, 0px) scale(0.7);
}
#customers-testimonials.owl-carousel .owl-dots {
  display: inline-block;
  width: 100%;
  text-align: center;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot span {
  background: #3190e7;
  display: inline-block;
  height: 20px;
  margin: 0 2px 5px;
  transform: translate3d(0px, -50%, 0px) scale(0.3);
  transform-origin: 50% 50% 0;
  transition: all 250ms ease-out 0s;
  width: 20px;
}

.navaco:active {
  border: 1px solidyellow;
  border-radius: 4px;
  color: bisque;
}
.moro:hover {
  border: 1px solid white;
  border-radius: 18px;
  color: white;
  text-shadow: burlywood;
}
.bas {
  color: gray;
}
/*zoom css*/
.zoomi {
  transition: transform 0.3s ease;
}
.zoomi:hover {
  transform: scale(1.02);
}
/*zoom css*/
.shadi {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);

  transition: box-shadow 0.3s ease-in-out;
}
.shadi:hover {
  box-shadow: 0px 0px 10px #ac2d5a;
}
/* fade in Window */
.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}
.fade-in.show {
  opacity: 0;
}
/* flying element*/
.flying-element {
  opacity: 0;
  transform: translateX(100%);
  /* transform: translateY(100px) scale(0.5); */
  transition: transform 0.5s ease;
}
.flying-element.zoomin {
  transform: scale(0.5);
  /* transform: translateY(0) scale(1); */
}
/* fly from left*/

.flying-element.flyleft {
  transform: translateX(50px);
}
/* shoe on focus*/
/*.show-on-focus {
  display: none;
}
.show-on-focus:focus + .show-on-focus-target {
  display: block;
}
.show-on-focus-target {
  display: none;
})*/
/* slide css */

:after,
:before,
* {
  box-sizing: border-box;
}
.container {
  width: 1200px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
body {
  margin: 0;
  font-family: "Rajdhani", sans-serif;
}
h2 {
  line-height: 1.1;
}
.hero-slider {
  width: 100%;
  height: 700px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 0;
}

@media (max-width: 991px) {
  .hero-slider {
    height: 600px;
  }
}

@media (max-width: 767px) {
  .hero-slider {
    height: 500px;
  }
}

.hero-slider .swiper-slide {
  overflow: hidden;
  color: #fff;
}

.hero-slider .swiper-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.hero-slider .slide-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.hero-slider .swiper-button-prev,
.hero-slider .swiper-button-next {
  background: transparent;
  width: 55px;
  height: 55px;
  line-height: 53px;
  margin-top: -30px;
  text-align: center;
  border: 2px solid #d4d3d3;
  border-radius: 55px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
.hero-slider:hover .swiper-button-prev,
.hero-slider:hover .swiper-button-next {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

@media (max-width: 767px) {
  .hero-slider .swiper-button-prev,
  .hero-slider .swiper-button-next {
    display: none;
  }
}

.hero-slider .swiper-button-prev {
  left: 25px;
  transform: translateX(50px);
}

.hero-slider .swiper-button-prev:before {
  font-family: "Font Awesome 5 Free";
  content: "\f060";
  font-size: 15px;
  color: #d4d3d3;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  font-weight: 900;
}

.hero-slider .swiper-button-next {
  right: 25px;
  transform: translateX(-50px);
}

.hero-slider .swiper-button-next:before {
  font-family: "Font Awesome 5 Free";
  content: "\f061";
  font-size: 15px;
  color: #d4d3d3;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  font-weight: 900;
}

.hero-slider .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  text-align: left;
  line-height: 12px;
  font-size: 12px;
  color: #000;
  opacity: 0.3;
  background: #fff;
  transition: all 0.2s ease;
}

.hero-slider .swiper-pagination-bullet-active {
  opacity: 1;
}

.hero-slider .swiper-container-horizontal > .swiper-pagination-bullets,
.hero-slider .swiper-pagination-custom,
.hero-slider .swiper-pagination-fraction {
  bottom: 30px;
}

@media screen and (min-width: 992px) {
  .hero-slider .swiper-container-horizontal > .swiper-pagination-bullets,
  .hero-slider .swiper-pagination-custom,
  .hero-slider .swiper-pagination-fraction {
    /*     display: none; */
  }
}
.swiper-pagination {
  text-align: left;
}

.hero-slider .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 50px;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 767px) {
  .hero-slider .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 30px;
  }
}
/*--------------------------------------------------------------
	#hero-style
--------------------------------------------------------------*/
.hero-style {
  height: 850px;
  transition: all 0.4s ease;
}

@media (max-width: 991px) {
  .hero-style {
    height: 600px;
  }
}

@media (max-width: 767px) {
  .hero-style {
    height: 500px;
  }
}

@media screen and (min-width: 992px) {
  .hero-style .container {
    padding-top: 95px;
  }
}

.hero-style .slide-title,
.hero-style .slide-text,
.hero-style .slide-btns {
  max-width: 690px;
}

.hero-style .slide-title h2 {
  font-size: 100px;
  font-weight: 600;
  line-height: 1;
  color: #ffffff;
  margin: 0 0 40px;
  text-transform: capitalize;
  transition: all 0.4s ease;
}

@media (max-width: 1199px) {
  .hero-style .slide-title h2 {
    font-size: 75px;
  }
}

@media (max-width: 991px) {
  .hero-style .slide-title h2 {
    font-size: 50px;
    margin: 0 0 35px;
  }
}

@media (max-width: 767px) {
  .hero-style .slide-title h2 {
    font-size: 35px;
    margin: 0 0 30px;
  }
}

.hero-style .slide-text p {
  opacity: 0.8;
  font-family: Rajdhani;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: normal;
  color: #ffffff;
  margin: 0 0 40px;
  transition: all 0.4s ease;
}

@media (max-width: 767px) {
  .hero-style .slide-text p {
    font-size: 16px;
    font-size: 1rem;
    font-weight: normal;
    margin: 0 0 30px;
  }
}

.hero-style .slide-btns > a:first-child {
  margin-right: 10px;
}

/*--------------------------------------------------------------
	#button-style
--------------------------------------------------------------*/
.theme-btn,
.theme-btn-s2 {
  background-color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
  color: #2b3b95;
  padding: 9px 32px;
  border: 0;
  border-radius: 3px;
  text-transform: uppercase;
  display: inline-block;
  line-height: initial;
  transition: all 0.4s ease;
}
a {
  text-decoration: none;
  transition: all 0.2s ease;
}
.theme-btn-s2 {
  background-color: rgba(255, 255, 255, 0.9);
  color: #131e4a;
}
.theme-btn:hover,
.theme-btn-s2:hover,
.theme-btn:focus,
.theme-btn-s2:focus,
.theme-btn:active,
.theme-btn-s2:active {
  background-color: #2b3b95;
  color: #fff;
}
.theme-btn-s3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #ffffff;
  text-transform: uppercase;
}
i.fa-chevron-circle-right {
  height: 22px;
  width: 22px;
}
a:hover {
  text-decoration: none;
}
@media (max-width: 991px) {
  .theme-btn,
  .theme-btn-s2,
  .theme-btn-s3 {
    font-size: 13px;
    padding: 15px 25px;
  }
}
@media (max-width: 767px) {
  .theme-btn,
  .theme-btn-s2 {
    padding: 13px 20px;
    font-size: 13px;
  }
}
.map {
  width: auto;
}
.fome {
  font-size: 16px;
  font-family: Arial, sans-serif;
}
.titlefome {
  font-size: 18px;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.fome40 {
  font-size: 40px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.crite {
  font-size: 17px;
  font-family: Arial, Helvetica, sans-serif;
}

/* Media Query for desktop */
@media (min-width: 992px) {
  .fome {
    font-size: 21px;
    font-family: Georgia, "Times New Roman", Times, serif;
  }
  .map {
    width: 580px;
  }
  .titlefome {
    font-size: 21px;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-weight: bold;
  }
  .crite {
    font-size: 17px;
    font-family: Arial, Helvetica, sans-serif;
  }
  .fome40 {
    font-size: 40px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .fome {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
  }
  .map {
    width: auto;
  }
  .crite {
    font-size: 17px;
    font-family: Arial, Helvetica, sans-serif;
  }
  .titlefome {
    font-size: 19px;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-weight: bold;
  }
  .fome40 {
    font-size: 37px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }
}
@media (max-width: 767px) {
  .fome {
    font-size: 15px;
    font-family: helvetica, sans-serif, arial;
  }
  .map {
    width: auto;
  }
  .titlefome {
    font-size: 18px;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-weight: bold;
  }
  .crite {
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
  }
  .fome40 {
    font-size: 34px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }
}
.loading-Icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loading-Icon-image {
  animation: zoom 2s ease-in-out infinite;
  width: 100px;
  height: 100px;
}
@keyframes zoom {
  0% {
    opacity: 0.5;
    filter: brightness(50%);
  }
  50% {
    opacity: 1;
    filter: brightness(100%);
  }
  100% {
    opacity: 0.5;
    filter: brightness(50%);
  }
}
