@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "swiper/swiper-bundle.css";

body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.color10 {
  background-color: #9e142a;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.Gradient1 {
  background-image: linear-gradient(to top, #9e142a, black);
  font-family: "calibri";
}
.Gradient2 {
  background-image: linear-gradient(to left, #9e142a, black);
  font-family: "calibri";
}
